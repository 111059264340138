.mint-container {
 
  border-style: solid;
  border-width: 6px;
  border-color: rgb(151, 224, 74);
  border-radius: 20px 20px 20px 20px;
  width: 250px;
  text-align: center;
  margin-top: auto;
  padding-top: 3%;
  padding-bottom: 5%;
  font-size: 1.3em;
}

.mint-container p{}

#mint {
  padding-top: 10%;
  width: auto;
  text-align: center
}

#mintButton,
#clubSign {
  width: 33%;
  height: 500px;
  float: left;
}

.clubSign,.mintButton {
  width: 77.7%;
  float: left;
  align-content: center;
  margin: 11%;
}
